/**
 * Global Styles
 */

:root {
  --main-bg-color: #eceff1;
  --panel-bg-color: #f9f9f9;
  --light-bg-color: #fff;
  --main-gradient-color: linear-gradient(to bottom, #f9f9f9 0%, #e5e5e5 100%);

  /* Borders */
  --main-border-color: #ccc;
  --main-border-style: solid;
  --main-border-width: 1px;
  --main-border: 1px solid #ccc;

  /* Fonts */
  --main-font: "RobotoArion", Arial, sans-serif;
  --main-font-size: 13px;
  --main-font-size-sm: 10px;
  --main-font-size-lg: 16px;

  --main-text-color: #000;

  --main-text-color-green: #090;
  --main-text-color-red: #900;
  --main-text-color-blue: #009;

  --main-text-color-green2: #0f0;
  --main-text-color-red2: #f00;
  --main-text-color-blue2: #00f;

  --label-text-color: #666;
  --label-font-size: 10px;

  --main-highlight-color: #0fd;
  --main-inactive-color: #ddd;

  --main-text-color-mandatory: #f00;

  --main-placeholder-color: #aaa;

  /* Tables */
  --table-row-bg: var(--light-bg-color);
  --table-alternating-row-bg: #f6f7f8;
  --table-header-bg: var(--panel-bg-color);

  /* Days */
  --day-color-saturday: #f38630;
  --day-color-sunday: #fa6900;
  --day-color-holiday: #69d2e7;
  --day-color-holiday2: #a7dbd8;
  --day-color-school-holiday: #faee7b;

  /* Buttons */
  --main-button-bg-color: var(--panel-bg-color);
  --hover-button-bg-color: #dedede;
  --main-button-text-color: var(--main-text-color);
  --hover-button-text-color: var(--main-highlight-color);
  --inactive-button-text-color: var(--main-inactive-color);

  /* Dpl / Kalender */
  --row-bg-even: #fff;
  --row-bg-odd: #f5f5f5;

  /* Dpl */
  --dpl-text-color: var(--main-text-color);
  --dpl-index-color: var(--main-text-color);

  /* Inputs*/
  --input-default-bg-color: #fff;
}

html {
  height: 100%;
  font-size: var(--main-font-size);
}

body {
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  margin: 0px;
  height: 100%;
  overflow-x: hidden;
}

@media print {
  html,
  body {
    height: 100% !important;
  }
}
/*a {*/
/*  color: #0df;*/
/*}*/

:global #toolbar_wrapper {
  background-color: #f9f9f9;
  opacity: 0.99;
  height: 3.18em;
  width: 100%;
  border: 1px solid #eee;
  color: #333;
  vertical-align: middle;
  z-index: 100;
  padding: 1px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: fixed;
  z-index: 200;
  box-shadow: 0 0 5px #ccc;
}

:global #toolbar {
  flex-grow: 1;
  gap: 0.3em;
  display: flex;
  vertical-align: middle;
  align-items: center;
  align-content: center;
}

:global #info_container {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
}

:global .loggedOut > #toolbar_wrapper {
  display: none;
}

:global .BaseTable__table-main {
  outline: none !important;
}

:global .react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 6px 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  right: -15px;
}

:global .react-datepicker__close-icon::after {
  cursor: pointer;
  color: #ccc;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

:global .fullheight {
  height: 100%;
}

@media print {
  :global .fullheight {
    height: auto;
  }
}

:global #app {
  height: 100%;
}

:global #app_wrapper {
  height: 100%;
}

:global #main_area {
  height: 100%;
}

:global #content {
  height: 100%;
}

:global #content {
  padding-top: 3.18em;
  margin-top: 0;
  padding-left: 2.76em;
  margin-left: 0;
}

:global #electroncontent {
  height: 100%;
}

:global #electroncontent {
  margin-top: 0;
}

:global .red {
  color: #f00;
}
:global .blue {
  color: #009;
}
:global .green {
  color: #090;
}

:global .darkgreen {
  color: #060;
}

:global .yellow {
  color: #fd0;
}

:global .gray {
  color: #ccc;
}

:global .orange {
  color: #ffa500;
}

:global .darkred {
  color: #900;
}

:global .whiteBg {
  background-color: #fff;
}

:global .Samstag {
  background-color: var(--day-color-saturday);
}

:global .Sonntag {
  background-color: var(--day-color-sunday);
}

:global .F.Samstag {
  background-color: var(--day-color-saturday);
  background: linear-gradient(
    to bottom left,
    var(--day-color-saturday) 50%,
    var(--day-color-holiday) 50%
  );
}

:global .F.Sonntag {
  background-color: var(--day-color-sunday);
  background: linear-gradient(
    to bottom left,
    var(--day-color-sunday) 50%,
    var(--day-color-holiday) 50%
  );
}

:global .H.Samstag {
  background-color: var(--day-color-saturday);
  background: linear-gradient(
    to bottom,
    var(--day-color-saturday) 50%,
    var(--day-color-holiday2) 50%
  );
}

:global .H.Sonntag {
  background-color: var(--day-color-sunday);
  background: linear-gradient(
    to bottom left,
    var(--day-color-sunday) 50%,
    var(--day-color-holiday2) 50%
  );
}

:global .S.Samstag {
  background-color: var(--day-color-saturday);
  background: linear-gradient(
    to bottom left,
    var(--day-color-saturday) 50%,
    var(--day-color-school-holiday) 50%
  );
}

:global .S.Sonntag {
  background-color: var(--day-color-sunday);
  background: linear-gradient(
    to bottom left,
    var(--day-color-sunday) 50%,
    var(--day-color-school-holiday) 50%
  );
}

:global .Samstaginvert {
  color: var(--day-color-saturday);
}

:global .Sonntaginvert {
  color: var(--day-color-sunday);
}

:global .Feiertag {
  background-color: var(--day-color-holiday);
}

:global .T-Samstag {
  color: var(--day-color-saturday);
}

:global .T-Sonntag {
  color: var(--day-color-sunday);
}

:global .T-Feiertag {
  color: var(--day-color-holiday);
}

:global .T-F {
  color: var(--day-color-holiday);
}

:global .F {
  background-color: var(--day-color-holiday);
}

:global .FF {
  background-color: var(--day-color-holiday);
}

:global .Hochfeiertag {
  background-color: var(--day-color-holiday2);
}

:global .H {
  background-color: var(--day-color-holiday2);
}

:global .Schulferien {
  background-color: var(--day-color-school-holiday);
}

:global .S {
  background-color: var(--day-color-school-holiday);
}

/*:global [data-fz="U"] {*/
/*background-color: #0f0;*/
/*}*/

/*:global [data-fz="K"] {*/
/*background-color: #f00;*/
/*}*/

/*:global [data-fz="D"] {*/
/*background-color: #a3f;*/
/*}*/

:global .alert-danger {
  color: #900;
}

:global .empty_row {
  background-color: none;
}
:global input {
  /*-webkit-appearance: none;*/
  /*border-style: solid;*/
  /*box-shadow: none !important;*/
  /*font-family: inherit;*/
}
/*
input {
  padding: 0.4em 0.6em;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

:global input {
  -webkit-appearance: none;
  border-style: solid;
  box-shadow: none !important;
}

:global input[type="checkbox"] {
  -webkit-appearance: checkbox;
  border-style: solid;
  box-shadow: none !important;
}

:global input[type="radio"] {
  -webkit-appearance: checkbox;
  border-style: solid;
  box-shadow: none !important;
}

:global .inaktiv {
  opacity: 0.6;
  color: #999 !important;
}

:global .center {
  text-align: center;
}

:global .Select-control {
  height: 30px;
}
:global .Select-placeholder {
  line-height: 28px;
  font-size: 10px;
}
:global .Select-value {
}

:global .Select-value-label {
}

:global .Select-input {
  height: 26px;
}

:global .Select-input > input {
  padding: 4px 0 12px;
}

:global input[type="text"] {
  padding: 0.3em !important;
}

:global input[type="text"] {
  padding: 0.3em !important;
}

:global label {
  font-size: 12px;
}

:global .input-sm {
}

:global .input-lg {
  height: 30px !important;
}

:global .input-align-right {
  text-align: right;
}

:global .Select-menu-outer {
  font-size: 12px !important;
}

:global .sm .Select-control {
  height: 25px;
}
:global .sm .Select-placeholder {
  line-height: 24px;
  font-size: 10px;
}
:global .sm .Select-value {
  line-height: 13px !important;
}

:global .sm .Select-value-label {
  line-height: 27px;
  font-size: 11px;
}

:global .sm.Select--multi .Select-value-label {
  line-height: 11px;
}

:global .sm .Select-input {
  height: 19px;
}

:global .sm .Select-input > input {
  padding: 4px 0 12px;
}

:global input[type="text"] {
  padding: 0.3em !important;
}

:global label {
  font-size: 12px;
} */

/*:global input {*/
/*  -webkit-appearance: none;*/
/*}*/

/*:global input[type="checkbox"] {*/
/*  -webkit-appearance: checkbox;*/
/*  border-style: solid;*/
/*  box-shadow: none !important;*/
/*}*/

/*:global input[type="radio"] {*/
/*  -webkit-appearance: checkbox;*/
/*  border-style: solid;*/
/*  box-shadow: none !important;*/
/*}*/

:global .Select-menu-outer {
  font-size: 12px !important;
}

:global .react-tabs__tab-list {
  border-bottom: 1px solid #ddd;
  margin: 0 0 0px !important;
  padding: 0;
}

:global .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  color: #999;
  font-size: 9px;
}

:global .react-tabs__tab--selected {
  background: #f9f9f9;
  border-color: #ddd;
  color: black;
  border-radius: 2px 2px 0 0;
}

:global .react-tabs__tab-panel {
  display: none;
}

:global .react-tabs__tab-panel--selected {
  display: block;
}

:global .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

:global .react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

:global .react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

:global .ReactVirtualized__Grid {
  outline: none;
}

:global .ReactVirtualized__Table__headerColumn {
  outline: none;
}

:global .black {
  color: #000;
}

:global .small {
  font-size: 8px;
}

:global .right {
  float: right;
}

:global .fading-appear {
  opacity: 0.01;
}

:global .fading-appear.fading-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

:global .fading-enter {
  opacity: 0.01;
}

:global .fading-enter.fading-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

:global .fading-leave {
  opacity: 1;
}

:global .fading-leave.fading-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

:global .sortableHelper {
  z-index: 999;
}

:global .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

:global .selectableText {
  -webkit-touch-callout: all; /* iOS Safari */
  -webkit-user-select: all; /* Safari */
  -khtml-user-select: all; /* Konqueror HTML */
  -moz-user-select: all; /* Firefox */
  -ms-user-select: all; /* Internet Explorer/Edge */
  user-select: all; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

:global input {
  /*!* border: none !important; *!*/
  /*border-left:none;*/
  /*border-right:none;*/
  /*border-top:none;*/
  /*background-color: #f9f9f9;*/
  /*border-bottom: 1px solid #ccc;*/
  /*!*border-radius: 0px !important;*!*/
  /*outline: none;*/
}
/*
:global input:hover {
  border: none !important;
  border-bottom: 1px solid #0fd ;
  border-radius: 0px !important;
}

:global input:focus {
  border-left:none;
  border-right:none;
  border-top:none;
  background-color: #f9f9f9;
  border-bottom: 1px solid #0fd !important;
  border-radius: 0px !important;
} */

:global .linethrough {
  text-decoration: line-through;
}

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
  -webkit-user-select: none;
  user-select: none;
  /* cursor: default; */
}

:global .Popover-tip {
  fill: #999;
}

:global .table_row_alternating {
  background-color: #f6f7f8 !important;
}

:global .table_row_selected {
  background-color: #007eff1a !important;
}

@font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*
@font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
} */

@font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
} */

@font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* @font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: medium;
  font-style: italic;
} */

@font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* @font-face {
  font-family: "RobotoArion";
  src: url("/static/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: thin;
  font-style: italic;
} */

@font-face {
  font-family: "RobotoArionMono";
  src: url("/static/fonts/RobotoMono-Italic-VariableFont_wght.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "RobotoArionMono";
  src: url("/static/fonts/RobotoMono-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

:global .react-resizable {
  position: relative;
}
:global .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
:global .react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
:global .react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
:global .react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
:global .react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
:global .react-resizable-handle-w,
:global .react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
:global .react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
:global .react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
:global .react-resizable-handle-n,
:global .react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
:global .react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
:global .react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

:global .p-menuitem-content svg {
  margin-right: 0.5rem;
  font-size: 1rem;
}

:global .p-tabview-nav-link svg {
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}

:global .p-virtualscroller {
  height: 100%;
}

:global .p-datatable .p-datatable-tbody > tr {
  transition: box-shadow 0.2s, background 0.3s;
}
